import React from "react";

import ContactForm from "../../../../ContactForm";

const MarineTrading = ({ useFormalAddress, lang, name = "marine-trading" }) => {
    return (
        <ContactForm
            urlEnding="marine-trading"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
            useFormalAddress={useFormalAddress}
            lang={lang}
        />
    );
};

export default MarineTrading;
